import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/dashboard/marketing",
          name: "dashboard-marketing",
          component: () => import("@/view/pages/dashboard/marketing.vue"),
        },
      ],
    },

    {
      path: "/transactions",
      name: "transactions",
      component: () => import("@/view/layout/Layout"),
      // component: () => import("@/view/pages/transaction/Transaction.vue"),
      children: [
        {
          path: "elastic",
          name: "elastic",
          // component: () => import("@/view/pages/transaction/Elastic.vue"),
          component: () => import("@/view/pages/transaction/v3/Index.vue"),
        },
        {
          path: "list",
          name: "listTransactions",
          component: () => import("@/view/pages/transaction/List.vue"),
        },
        {
          path: "detail",
          name: "detailTransactions",
          component: () => import("@/view/pages/transaction/Transaction.vue"),
        },
        {
          path: "auto-payment/list",
          name: "auto-payment",
          component: () => import("@/view/pages/transaction/AutoPayment.vue"),
        },
        {
          path: "auto-payment/detail/:id",
          name: "auto-payment",
          component: () =>
            import("@/view/pages/transaction/AutoPaymentDetail.vue"),
        },
        {
          path: "sync-status",
          name: "sync-status",
          component: () =>
            import("@/view/pages/transaction/SyncTransStatus.vue"),
        },

        {
          path: "with-voucher",
          name: "with-voucher",
          component: () => import("@/view/pages/transaction/Voucher.vue"),
        },
      ],
    },
    {
      path: "/stats",
      name: "stats",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "account-balance-daily",
          name: "accountBalanceDaily",
          component: () => import("@/view/pages/stats/AccountBalanceDaily.vue"),
        },

        {
          path: "bu-funtap",
          name: "buFuntap",
          component: () => import("@/view/pages/stats/BuFuntap.vue"),
        },
        {
          path: "source-money",
          name: "sourceMoney",
          component: () => import("@/view/pages/stats/SourceMoney.vue"),
        },
      ],
    },
    {
      path: "/reconcile",
      name: "reconcile",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "default",
          component: () => import("@/view/pages/reconcile/Reconcile.vue"),
        },
        {
          path: "transaction-detail",
          name: "transactionDetail",
          component: () =>
            import("@/view/pages/reconcile/TransactionDetail.vue"),
        },
        {
          path: "reconcile-cashback",
          name: "reconcileCashback",
          component: () => import("@/view/pages/tools/cashback/index.vue"),
        },
        {
          path: "export-data",
          name: "exportData",
          component: () => import("@/view/pages/reconcile/ExportData.vue"),
        },
        {
          path: "cashback",
          name: "cashback",
          component: () =>
            import("@/view/pages/reconcile_cashback/Reconcile.vue"),
        },
        {
          path: "cashback-detail",
          name: "cashbackDetail",
          component: () => import("@/view/pages/reconcile_cashback/Detail.vue"),
        },
        {
          path: "check-wallet",
          name: "checkWallet",
          component: () => import("@/view/pages/tools/checkWallet/index.vue"),
        },
        {
          path: "card",
          name: "Card",
          component: () => import("@/view/pages/tools/card/index.vue"),
        },
        {
          path: "internal",
          name: "Internal",
          component: () => import("@/view/pages/tools/internal/index.vue"),
        },
        {
          path: "sacombank/upload",
          name: "sacombank",
          component: () =>
            import("@/view/pages/reconcile_sacombank/Create.vue"),
        },
        {
          path: "sacombank",
          name: "detailSacombank",
          component: () =>
            import("@/view/pages/reconcile_sacombank/Reconcile.vue"),
        },
        {
          path: "sacombank/detail",
          name: "detailSacombank",
          component: () =>
            import("@/view/pages/reconcile_sacombank/Detail.vue"),
        },
        {
          path: "reconciles",
          name: "reconciles",
          component: () => import("@/view/pages/reconciles/Reconcile"),
        },
        {
          path: "reconciles/upload",
          name: "reconcilesUpload",
          component: () => import("@/view/pages/reconciles/Create"),
        },
        {
          path: "reconciles/detail",
          name: "reconcilesDetail",
          component: () => import("@/view/pages/reconciles/Detail"),
        },
        {
          path: "vnpay",
          name: "vnpay",
          component: () => import("@/view/pages/partners/vnpay/index.vue"),
        },
        {
          path: "charity",
          name: "charity",
          component: () => import("@/view/pages/tools/charity/index.vue"),
        },
        {
          path: "vbee",
          name: "vbee",
          component: () => import("@/view/pages/partners/vbee/index.vue"),
        },
        {
          path: "south-telecom",
          name: "southTelecom",
          component: () => import("@/view/pages/partners/south_telecom/index.vue"),
        },
        {
          path: "urbox",
          name: "reconcileUrbox",
          component: () => import("@/view/pages/reconcile/Urbox.vue"),
        },
        {
          path: "merchant",
          name: "reconcileMerchant",
          component: () => import("@/view/pages/reconcile/merchant/index.vue"),
        },
        {
          path: "napas/cashout",
          name: "reconcileNapasCashout",
          component: () => import("@/view/pages/reconcile/napas/CashOut.vue"),
        },
        {
          path: "kyc",
          name: "reconcileKyc",
          component: () => import("@/view/pages/reconcile/Kyc.vue"),
        },
        {
          path: "transaction-fee",
          name: "reconcileTransactionFee",
          component: () => import("@/view/pages/reconcile/transaction_fee/Index.vue"),
        },
      ],
    },
    {
      path: "/auto-reconcile",
      name: "auto-reconcile",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "received/aggregate",
          name: "reconcileReceivedAggregate",
          component: () => import("@/view/pages/auto-reconcile/received/Aggregate.vue"),
        },
        {
          path: "received/list/:id",
          name: "reconcileReceivedList",
          component: () => import("@/view/pages/auto-reconcile/TransactionList.vue"),
        },
        {
          path: "received/show/:id",
          name: "reconcileReceivedShow",
          component: () => import("@/view/pages/auto-reconcile/received/Show.vue"),
        },
        {
          path: "received/upload",
          name: "reconcileReceivedUpload",
          component: () => import("@/view/pages/auto-reconcile/received/Upload.vue"),
        },
        {
          path: "request/aggregate",
          name: "reconcileRequestAggregate",
          component: () => import("@/view/pages/auto-reconcile/request/Aggregate.vue"),
        },
        {
          path: "request/list/:id",
          name: "requestReceivedList",
          component: () => import("@/view/pages/auto-reconcile/TransactionList.vue"),
        },
        {
          path: "request/show/:id",
          name: "reconcileRequestShow",
          component: () => import("@/view/pages/auto-reconcile/request/Show.vue"),
        },
        {
          path: "response/aggregate",
          name: "reconcileResponseAggregate",
          component: () => import("@/view/pages/auto-reconcile/response/Aggregate.vue"),
        },
        {
          path: "response/list/:id",
          name: "responseReceivedList",
          component: () => import("@/view/pages/auto-reconcile/TransactionList.vue"),
        },
        {
          path: "response/show/:id",
          name: "reconcileResponseShow",
          component: () => import("@/view/pages/auto-reconcile/response/Show.vue"),
        },
      ]
    },
    {
      path: "/voucher",
      name: "voucher",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "distribute-history",
          name: "distributeHistory",
          component: () => import("@/view/pages/voucher/DistributeHistory.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login-1"),
        },
      ],
    },
    {
      path: "/ggauth",
      name: "loginGoogle",
      component: () => import("@/view/pages/auth/LoginGoogle"),
    },
    {
      path: "/tools",
      name: "tools",
      component: () => import("@/view/layout/Layout"),
      // component: () => import("@/view/pages/transaction/Transaction.vue"),
      children: [
        {
          path: "add-bin-code",
          name: "add-bin-code",
          component: () => import("@/view/pages/tools/AddBinCode.vue"),
        },
        {
          path: "config-notifications",
          name: "config-notifications",
          component: () => import("@/view/pages/tools/ConfigNotifications.vue"),
        },
        {
          path: "config-segment",
          name: "config-segment",
          component: () =>
            import("@/view/pages/tools/segment/ConfigSegment.vue"),
        },
        {
          path: "config-segment/segment-user/:id",
          name: "segment-user",
          component: () => import("@/view/pages/tools/segment/SegmentUser.vue"),
        },
        {
          path: "retry-card",
          name: "retry-card",
          component: () => import("@/view/pages/tools/RetryCard.vue"),
        },
        {
          path: "promotion",
          name: "promotion",
          component: () => import("@/view/pages/tools/promotion/List.vue"),
        },
        {
          path: "promotion/create",
          name: "createPromotion",
          component: () => import("@/view/pages/tools/promotion/Create.vue"),
        },
        {
          path: "promotion/update",
          name: "updatePromotion",
          component: () => import("@/view/pages/tools/promotion/Update.vue"),
        },
        {
          path: "service-log",
          name: "serviceLog",
          component: () => import("@/view/pages/tools/service/LogService.vue"),
        },
        {
          path: "fee",
          name: "fee",
          component: () => import("@/view/pages/tools/fee/Index.vue"),
        },
        {
          path: "fee/create",
          name: "fee-create",
          component: () => import("@/view/pages/tools/fee/Create.vue"),
        },
        {
          path: "fee/view",
          name: "fee-view",
          component: () => import("@/view/pages/tools/fee/Detail.vue"),
        },

        {
          path: "list-export",
          name: "listExport",
          component: () => import("@/view/pages/tools/statistics/Index.vue"),
        },
        {
          path: "rfm",
          name: "rfm",
          component: () => import("@/view/pages/tools/statistics/Rfm.vue"),
        },
        {
          path: "route-app",
          name: "routeApp",
          component: () => import("@/view/pages/tools/routeApp/Index.vue"),
        },
        {
          path: "route-app/create",
          name: "routeApp-create",
          component: () => import("@/view/pages/tools/routeApp/Create.vue"),
        },
        {
          path: "config-deeplink",
          name: "configDeeplink",
          component: () => import("@/view/pages/tools/configDeeplink/Index.vue"),
        },
        {
          path: "config-deeplink/create",
          name: "configDeeplink-create",
          component: () => import("@/view/pages/tools/configDeeplink/Create.vue"),
        },
        {
          path: "schedule",
          name: "schedule",
          component: () => import("@/view/pages/tools/schedule/Index.vue"),
        },
        {
          path: "schedule/create",
          name: "scheduleCreate",
          component: () => import("@/view/pages/tools/schedule/Create.vue"),
        },
        {
          path: "schedule/update",
          name: "scheduleUpdate",
          component: () => import("@/view/pages/tools/schedule/Update.vue"),
        },
        {
          path: "message-template",
          name: "messageTemplate",
          component: () => import("@/view/pages/tools/messageTemplate/Index.vue"),
        },
        {
          path: "message-template/create",
          name: "messageTemplate-create",
          component: () => import("@/view/pages/tools/messageTemplate/Create.vue"),
        },
        {
          path: "risk/notification",
          name: "riskNotification",
          component: () => import("@/view/pages/tools/risk/notify/Index.vue"),
        },
        {
          path: "service-app",
          name: "serviceApp",
          component: () => import("@/view/pages/tools/serviceApp/Index.vue"),
        },
        {
          path: "service-app/create",
          name: "serviceApp-create",
          component: () => import("@/view/pages/tools/serviceApp/Create.vue"),
        },
        {
          path: "bounce-mail",
          name: "bounceMail",
          component: () => import("@/view/pages/tools/bounceMail/Index.vue"),
        },
        {
          path: "bounce-mail/create",
          name: "bounceMailCreate",
          component: () => import("@/view/pages/tools/bounceMail/Create.vue"),
        },
        {
          path: "mail-merchant",
          name: "mailMerchant",
          component: () => import("@/view/pages/tools/mailMerchant/Index.vue"),
        },
        {
          path: "mail-merchant/create",
          name: "mailMerchantCreate",
          component: () => import("@/view/pages/tools/mailMerchant/Create.vue"),
        },
        {
          path: "risk/notification",
          name: "riskNotification",
          component: () => import("@/view/pages/tools/risk/notify/Index.vue"),
        },
        {
          path: "risk/scoring",
          name: "riskScoring",
          component: () => import("@/view/pages/tools/risk/riskScoring/Create.vue"),
        },
        {
          path: "okr",
          name: "okr",
          component: () => import("@/view/pages/tools/okr/Index.vue"),
        },
        {
          path: "okr/create",
          name: "okrCreate",
          component: () => import("@/view/pages/tools/okr/Create.vue"),
        },
        {
          path: "okr/update",
          name: "okrUpdate",
          component: () => import("@/view/pages/tools/okr/Update.vue"),
        },
        {
          path: "segment/action",
          name: "segmentAction",
          component: () => import("@/view/pages/tools/segmentAction/Index.vue"),
        },
        {
          path: "segment/action/create",
          name: "segmentActionCreate",
          component: () => import("@/view/pages/tools/segmentAction/Create.vue"),
        },
        {
          path: "segment/action/users",
          name: "segmentActionDetail",
          component: () => import("@/view/pages/tools/segmentAction/ListUser.vue"),
        },
        {
          path: "users/block",
          name: "usersBlock",
          component: () => import("@/view/pages/tools/account/Lock.vue"),
        },
        {
          path: "users/account",
          name: "AccountList",
          component: () => import("@/view/pages/tools/account/List.vue"),
        },
        {
          path: "users/account/view/:id",
          name: "AccountView",
          component: () => import("@/view/pages/tools/account/View.vue"),
        },
        {
          path: "users/account/edit/:id",
          name: "AccountEdit",
          component: () => import("@/view/pages/tools/account/Edit.vue"),
        },
        {
          path: "bank/maintenance",
          name: "bankMaintenance",
          component: () => import("@/view/pages/tools/bank/Maintenance.vue"),
        },
        {
          path: "bank/maintenance/create",
          name: "createMaintenance",
          component: () => import("@/view/pages/tools/bank/CreateMaintenance.vue"),
        },
        {
          path: "bank/maintenance/update",
          name: "updateMaintenance",
          component: () => import("@/view/pages/tools/bank/UpdateMaintenance.vue"),
        },
        {
          path: "bank/monitor",
          name: "bankMonitor",
          component: () => import("@/view/pages/tools/bank/Monitor.vue"),
        },
        {
          path: "user/request-lock",
          name: "userRequestLock",
          component: () => import("@/view/pages/tools/account/requestLock/Index.vue"),
        },
        {
          path: "user/request-lock/detail",
          name: "userRequestLockDetail",
          component: () => import("@/view/pages/tools/account/requestLock/Detail.vue"),
        },
      ],
    },

    // {
    //   path: "*",
    //   redirect: "/404",
    // },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
    {
      // the 403 route, when none of the above matches
      path: "/403",
      name: "403",
      component: () => import("@/view/pages/error/Error-403.vue"),
    },
    {
      path: "/credit-card",
      name: "credit-card",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "list",
          name: "credit-card-list",
          component: () => import("@/view/pages/credit/ListCard.vue"),
        },
        {
          path: "detail",
          name: "credit-card-detail",
          component: () => import("@/view/pages/credit/ListTransaction.vue"),
        },
      ],
    },
    {
      path: "/card-store",
      name: "card-store",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "list",
          name: "cardList",
          component: () => import("@/view/pages/card-store/ListCard.vue"),
        },
        {
          path: "import",
          name: "cardImport",
          component: () => import("@/view/pages/card-store/ImportCard.vue"),
        }
      ],
    },
    {
      path: "/account",
      name: "account",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "enterprise",
          name: "enterprise",
          component: () => import("@/view/pages/account/Enterprise.vue"),
        },
        {
          path: "enterprise/detail/:id",
          name: "enterpriseDetail",
          component: () => import("@/view/pages/account/Detail.vue"),
        },
        {
          path: "black-list",
          name: "blackList",
          component: () => import("@/view/pages/black_list/ListAll.vue"),
        },
        {
          path: "black-list/add",
          name: "blackListAdd",
          component: () => import("@/view/pages/black_list/Add.vue"),
        },
        {
          path: "black-list",
          name: "blackList",
          component: () => import("@/view/pages/black_list/ListAll.vue"),
        },
        {
          path: "black-list/add",
          name: "blackListAdd",
          component: () => import("@/view/pages/black_list/Add.vue"),
        },
      ],
    },
    {
      path: "/wallet-run/mission",
      name: "wallet-run",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "mission",
          component: () => import("@/view/pages/wallet_run/mission/List.vue"),
        },
        {
          path: "create",
          name: "mission_create",
          component: () => import("@/view/pages/wallet_run/mission/From.vue"),
        },
        {
          path: "edit/:id",
          name: "mission_edit",
          component: () => import("@/view/pages/wallet_run/mission/From.vue"),
        },
      ],
    },
    {
      path: "/wallet-run/level",
      name: "wallet-run-level",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "level",
          component: () => import("@/view/pages/wallet_run/Level/List.vue"),
        },
        {
          path: "create",
          name: "create_level",
          component: () => import("@/view/pages/wallet_run/Level/From.vue"),
        },
        {
          path: "edit/:id",
          name: "level_edit",
          component: () => import("@/view/pages/wallet_run/Level/From.vue"),
        },
        {
          path: "view/:id",
          name: "level_view",
          component: () => import("@/view/pages/wallet_run/Level/From.vue"),
        },
      ],
    },
    {
      path: "/user",
      name: "user",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "add-user",
          name: "add-user",
          component: () => import("@/view/pages/user/AddUser.vue"),
        },
        {
          path: "list-user",
          name: "list-user",
          component: () => import("@/view/pages/user/ListUser.vue"),
        },
        {
          path: "detail-user",
          name: "detail-user",
          component: () => import("@/view/pages/user/DetailUser.vue"),
        },
      ],
    },
    {
      path: "/wallet-run/report",
      name: "wallet-run-report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "activities",
          name: "Activity",
          component: () =>
            import("@/view/pages/wallet_run/Report/Activity.vue"),
        },
        {
          path: "missions",
          name: "Mission",
          component: () => import("@/view/pages/wallet_run/Report/Mission.vue"),
        },
      ],
    },
    {
      path: "/account",
      name: "account",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "enterprise",
          name: "enterprise",
          component: () => import("@/view/pages/account/Enterprise.vue"),
        },
        {
          path: "enterprise/detail/:id",
          name: "enterpriseDetail",
          component: () => import("@/view/pages/account/Detail.vue"),
        },
        {
          path: "merchant",
          name: "merchant",
          component: () => import("@/view/pages/account/merchant/ListAll.vue"),
        },
        {
          path: "merchant/create",
          name: "merchantCreate",
          component: () => import("@/view/pages/account/merchant/Create.vue"),
        },
      ],
    },
    {
      path: "/campaign/mission",
      name: "campaign-mission",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "mission_campaign",
          component: () => import("@/view/pages/campaign/mission/List.vue"),
        },
        {
          path: "create",
          name: "campaign_mission_create",
          component: () => import("@/view/pages/campaign/mission/From.vue"),
        },
        {
          path: "edit/:id",
          name: "campaign_mission_edit",
          component: () => import("@/view/pages/campaign/mission/From.vue"),
        },
      ],
    },
    {
      path: "/campaign/challenge",
      name: "campaign-challenge",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "challenge_campaign",
          component: () => import("@/view/pages/campaign/challenge/List.vue"),
        },
        {
          path: "create",
          name: "campaign_challenge_create",
          component: () => import("@/view/pages/campaign/challenge/From.vue"),
        },
        {
          path: "edit/:id",
          name: "campaign_challenge_edit",
          component: () => import("@/view/pages/campaign/challenge/From.vue"),
        },
        {
          path: "clone/:id",
          name: "campaign_challenge_clone",
          component: () => import("@/view/pages/campaign/challenge/Clone.vue"),
        },
      ],
    },
    {
      path: "/campaign/combo",
      name: "campaign-combo",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "combo_campaign",
          component: () => import("@/view/pages/campaign/combo/List.vue"),
        },
        {
          path: "create",
          name: "campaign_combo_create",
          component: () => import("@/view/pages/campaign/combo/From.vue"),
        },
        {
          path: "edit/:id",
          name: "campaign_combo_edit",
          component: () => import("@/view/pages/campaign/combo/From.vue"),
        },
      ],
    },
    // Chiến dịch sinh viên
    {
      path: "/campaign/university",
      name: "campaign-university",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "campaign_university",
          component: () =>
            import("@/view/pages/campaign/universities/List.vue"),
        },
        {
          path: "create",
          name: "campaign_university_create",
          component: () =>
            import("@/view/pages/campaign/universities/From.vue"),
        },
        {
          path: "edit/:id",
          name: "campaign_university_edit",
          component: () =>
            import("@/view/pages/campaign/universities/From.vue"),
        },
        {
          path: "upload",
          name: "campaign_university_upload",
          component: () =>
            import("@/view/pages/campaign/universities/Create.vue"),
        },
      ],
    },

    {
      path: "/campaign/report-student",
      name: "report-student",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "report_student",
          component: () =>
            import(
              "@/view/pages/campaign/universities/ReportCampaignStudent.vue"
            ),
        },
      ],
    },

    {
      path: "/campaign/setting",
      name: "campaign-setting",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "setting_campaign",
          component: () => import("@/view/pages/campaign/setting/From.vue"),
        },
        {
          path: "rank-config",
          name: "setting_rank_config",
          component: () => import("@/view/pages/campaign/setting/FormRank.vue"),
        },
      ],
    }, //
    {
      path: "/campaign/report",
      name: "campaign-report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "challenges",
          name: "campaign_report_challenges",
          component: () => import("@/view/pages/campaign/report/Challenge.vue"),
        },
        {
          path: "missions",
          name: "campaign_report_missions",
          component: () => import("@/view/pages/campaign/report/Missions.vue"),
        },
        {
          path: "gifts",
          name: "campaign_report_gifts",
          component: () => import("@/view/pages/campaign/report/Gift.vue"),
        },
      ],
    },
    {
      path: "/combo-voucher/event",
      name: "campaign-report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "",
          name: "combo_voucher_campaign",
          component: () =>
              import("@/view/pages/combo_voucher/campaign/Index.vue"),
        },
        {
          path: "create",
          name: "combo_voucher_campaign_create",
          component: () => import("@/view/pages/combo_voucher/campaign/Form.vue"),
        },
        {
          path: "edit/:id",
          name: "combo_voucher_campaign_edit",
          component: () => import("@/view/pages/combo_voucher/campaign/Form.vue"),
        },
      ],
    },
    {
      path: "/combo-voucher/script",
      name: "combo-voucher-script",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "",
          name: "combo_voucher_script",
          component: () =>
              import("@/view/pages/combo_voucher/script/Index.vue"),
        },
        {
          path: "create",
          name: "combo_voucher_script_create",
          component: () => import("@/view/pages/combo_voucher/script/Form.vue"),
        },
        {
          path: "edit/:id",
          name: "combo_voucher_script_edit",
          component: () => import("@/view/pages/combo_voucher/script/Edit.vue"),
        },
      ],
    },
    {
      path: "/combo-voucher/report",
      name: "combo-voucher-report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "log-user-gifts",
          name: "combo_voucher_report_log_user_gifts",
          component: () =>
              import("@/view/pages/combo_voucher/report/LogUserGifts.vue"),
        },
        {
          path: "event-voucher-releases",
          name: "combo_voucher_report_event_voucher_releases",
          component: () =>
              import("@/view/pages/combo_voucher/report/EventVoucherRelease.vue"),
        },

      ],
    },
    {
      path: "/campaign/report",
      name: "campaign-report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "challenges",
          name: "campaign_report_challenges",
          component: () => import("@/view/pages/campaign/report/Challenge.vue"),
        },
        {
          path: "missions",
          name: "campaign_report_missions",
          component: () => import("@/view/pages/campaign/report/Missions.vue"),
        },
        {
          path: "gifts",
          name: "campaign_report_gifts",
          component: () => import("@/view/pages/campaign/report/Gift.vue"),
        },
        {
          path: "refers",
          name: "campaign_report_refers",
          component: () => import("@/view/pages/campaign/report/Refer.vue"),
        },
        {
          path: "refer-detail/:id",
          name: "campaign_report_refers_detail",
          component: () =>
            import("@/view/pages/campaign/report/ReferDetail.vue"),
        },
      ],
    },

    {
      path: "/campaign/category",
      name: "campaign-category",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "campaign_category_challenges",
          component: () => import("@/view/pages/campaign/category/List.vue"),
        },
        {
          path: "create",
          name: "campaign_category_crate",
          component: () => import("@/view/pages/campaign/category/From.vue"),
        },
        {
          path: "edit/:id",
          name: "campaign_category_edit",
          component: () => import("@/view/pages/campaign/category/From.vue"),
        },
      ],
    },
    {
      path: "/campaign/rule",
      name: "campaign-rule",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "campaign_rule_challenges",
          component: () => import("@/view/pages/campaign/rule/List.vue"),
        },
        {
          path: "create",
          name: "campaign_rule_crate",
          component: () => import("@/view/pages/campaign/rule/From.vue"),
        },
        {
          path: "edit/:id",
          name: "campaign_rule_edit",
          component: () => import("@/view/pages/campaign/rule/From.vue"),
        },
        {
          path: "banner",
          name: "campaign_rule_banner",
          component: () => import("@/view/pages/campaign/rule/Banner.vue"),
        },
        {
          path: "gifts",
          name: "campaign_rule_gifts",
          component: () => import("@/view/pages/campaign/rule/Gift.vue"),
        },
      ],
    },
    {
      path: "/campaign/faq",
      name: "campaign-faq",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "campaign_faq_challenges",
          component: () => import("@/view/pages/campaign/faq/List.vue"),
        },
        {
          path: "create",
          name: "campaign_faq_crate",
          component: () => import("@/view/pages/campaign/faq/From.vue"),
        },
        {
          path: "edit/:id",
          name: "campaign_faq_edit",
          component: () => import("@/view/pages/campaign/faq/From.vue"),
        },
      ],
    },
    {
      path: "/combo-voucher/event",
      name: "combo_voucher_campaign",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "",
          name: "combo_voucher_campaign",
          component: () =>
            import("@/view/pages/combo_voucher/campaign/Index.vue"),
        },
        {
          path: "create",
          name: "combo_voucher_campaign_create",
          component: () =>
            import("@/view/pages/combo_voucher/campaign/Form.vue"),
        },
        {
          path: "edit/:id",
          name: "combo_voucher_campaign_edit",
          component: () =>
            import("@/view/pages/combo_voucher/campaign/Form.vue"),
        },
      ],
    },
    {
      path: "/combo-voucher/script",
      name: "combo-voucher-script",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "",
          name: "combo_voucher_script",
          component: () =>
            import("@/view/pages/combo_voucher/script/Index.vue"),
        },
        {
          path: "create",
          name: "combo_voucher_script_create",
          component: () => import("@/view/pages/combo_voucher/script/Form.vue"),
        },
        {
          path: "edit/:id",
          name: "combo_voucher_script_edit",
          component: () => import("@/view/pages/combo_voucher/script/Edit.vue"),
        },
      ],
    },
    {
      path: "/combo-voucher/whitelist",
      name: "combo-voucher-whitelist",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "create",
          name: "combo_voucher_whitelist_create",
          component: () =>
            import("@/view/pages/combo_voucher/whitelist/Form.vue"),
        },
        {
          path: "edit/:id",
          name: "combo_voucher_whitelist_edit",
          component: () =>
            import("@/view/pages/combo_voucher/whitelist/Form.vue"),
        },
        {
          path: "/",
          name: "combo_voucher_whitelist_list",
          component: () =>
            import("@/view/pages/combo_voucher/whitelist/Index.vue"),
        },
      ],
    },
    {
      path: "/combo-voucher/report",
      name: "combo-voucher-report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "log-user-gifts",
          name: "combo_voucher_report_log_user_gifts",
          component: () =>
            import("@/view/pages/combo_voucher/report/LogUserGifts.vue"),
        },
        {
          path: "event-voucher-releases",
          name: "combo_voucher_report_event_voucher_releases",
          component: () =>
            import("@/view/pages/combo_voucher/report/EventVoucherRelease.vue"),
        },
      ],
    },
    {
      path: "/balance",
      name: "balance",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "daily",
          name: "daily",
          component: () => import("@/view/pages/balance/List.vue"),
        },
      ],
    },
    {
      path: "/point",
      name: "point",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "daily",
          name: "pointDaily",
          component: () => import("@/view/pages/point/List.vue"),
        },
      ],
    },
    {
      path: "/refer",
      name: "campaign-refer",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "campaign_refer",
          component: () => import("@/view/pages/refer/Form.vue"),
        },
      ],
    },
    {
      path: "/report",
      name: "report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "fee",
          name: "reportFee",
          component: () => import("@/view/pages/Report/fee/Index.vue"),
        },
        {
          path: "coupon",
          name: "reportCoupon",
          component: () => import("@/view/pages/Report/coupon/Index.vue"),
        },
        {
          path: "top-10",
          name: "reportTopTen",
          component: () => import("@/view/pages/Report/top_ten/Index.vue"),
        },
        {
          path: "transaction-situation",
          name: "reportTransactionSituation",
          component: () => import("@/view/pages/Report/situation/Index.vue"),
        },
        {
          path: "accounts",
          name: "reportAccounts",
          component: () => import("@/view/pages/Report/accounts/Account.vue"),
        },
        {
          path: "new-accounts",
          name: "reportNewAccounts",
          component: () => import("@/view/pages/Report/accounts/NewAccount.vue"),
        },
      ],
    },
    {
      path: "/admin",
      redirect: "/admin",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/role-permission",
          name: "role-permission",
          component: () => import("@/view/pages/admin/RolePermission.vue"),
        },
        {
          path: "/permission",
          name: "permission",
          component: () => import("@/view/pages/admin/Permission.vue"),
        },
        {
          path: "/create-role",
          name: "create-role",
          component: () => import("@/view/pages/admin/CreateRole.vue"),
        },
        {
          path: "/detail-role",
          name: "detail-role",
          component: () => import("@/view/pages/admin/DetailRole.vue"),
        },
      ],
    },

    {
      path: "/report",
      name: "report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "bank",
          name: "reportBank",
          component: () => import("@/view/pages/Report/bank/Index.vue"),
        },
      ],
    },

    {
      path: "/report",
      name: "report",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "payGate",
          name: "reportPayGate",
          component: () => import("@/view/pages/Report/paygate/Index.vue"),
        },
        {
          path: "provider",
          name: "reportProvider",
          component: () => import("@/view/pages/Report/provider/Index.vue"),
        },
        {
          path: "customer",
          name: "reportCustomer",
          component: () => import("@/view/pages/Report/customer/Index.vue"),
        },
      ],
    },
    {
      path: "/student-refer",
      name: "campaign_student_refer",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "campaign_student_refer_config",
          component: () => import("@/view/pages/refer-student/Form.vue"),
        },
        {
          path: "edit/:id",
          name: "campaign_student_refer_update",
          component: () => import("@/view/pages/refer-student/Form.vue"),
        },
        {
          path: "report-seeding",
          name: "campaign_student_refer_seeding",
          component: () => import("@/view/pages/refer-student/Seeding.vue"),
        },
        {
          path: "report-refer",
          name: "campaign_student_refer_report",
          component: () => import("@/view/pages/refer-student/Refer.vue"),
        },
      ],
    },
    {
      path: "/charity",
      name: "charity_config",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "organization/",
          name: "organization",
          component: () =>
            import("@/view/pages/charity/organization/Index.vue"),
        },
        {
          path: "organization/create",
          name: "organization-create",
          component: () => import("@/view/pages/charity/organization/Form.vue"),
        },
        {
          path: "organization/edit/:id",
          name: "organization-edit",
          component: () => import("@/view/pages/charity/organization/Form.vue"),
        },
        {
          path: "campaign-charities/",
          name: "campaign-charities",
          component: () => import("@/view/pages/charity/campaign/Index.vue"),
        },
        {
          path: "campaign-charities/create",
          name: "campaign-charities-create",
          component: () => import("@/view/pages/charity/campaign/Form.vue"),
        },
        {
          path: "campaign-charities/edit/:id",
          name: "campaign-charities-edit",
          component: () => import("@/view/pages/charity/campaign/Form.vue"),
        },
        {
          path: "users",
          name: "campaign-users",
          component: () => import("@/view/pages/charity/users/Index.vue"),
        },
      ],
    },
    // {
    //   path: "/statistics",
    //   name: "statistics",
    //   component: () => import("@/view/layout/Layout"),
    //   children: [
    //     {
    //       path: "list-export",
    //       name: "listExport",
    //       component: () => import("@/view/pages/tools/statistics/Index.vue"),
    //     },
    //     {
    //       path: "rfm",
    //       name: "rfm",
    //       component: () => import("@/view/pages/tools/statistics/Rfm.vue"),
    //     }
    //   ]
    // },

    // Route danh sách mốc nhận quà
    {
      path: "/campaign/mileStone",
      name: "campaign-mileStone",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "mileStone",
          component: () => import("@/view/pages/campaign/mileStone/List.vue"),
        },
        {
          path: "create",
          name: "mileStone_create",
          component: () => import("@/view/pages/campaign/mileStone/From.vue"),
        },
        {
          path: "edit/:id",
          name: "mileStone_edit",
          component: () => import("@/view/pages/campaign/mileStone/From.vue"),
        },
        {
          path: "view/:id",
          name: "level_view",
          component: () => import("@/view/pages/campaign/mileStone/From.vue"),
        },
      ],
    },
    {
      path: "/marketing",
      name: "marketing",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "voucher",
          name: "mkt_voucher",
          component: () => import("@/view/pages/marketing/Voucher.vue"),
        },
        {
          path: "user-transactions",
          name: "mkt_voucher",
          component: () => import("@/view/pages/marketing/List.vue"),
        },
      ]
    },
    // Route đua top
    {
      path: "/top-service",
      name: "top-service",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "top-service",
          component: () => import("@/view/pages/top_service/Index.vue"),
        },
        {
          path: "create",
          name: "top-service-create",
          component: () => import("@/view/pages/top_service/Form.vue"),
        },
        {
          path: "edit/:id",
          name: "top-service-edit",
          component: () => import("@/view/pages/top_service/Form.vue"),
        },
        {
          path: "analysis/:id",
          name: "top-service-analysis",
          component: () => import("@/view/pages/top_service/Analysis.vue"),
        },
        {
          path: "bot/:id",
          name: "top-service-bot",
          component: () => import("@/view/pages/top_service/Bot.vue"),
        },
        {
          path: "milestone/:id",
          name: "top-service-milestone",
          component: () => import("@/view/pages/top_service/MileStone.vue"),
        },
      ],
    },
    {
      path: "/marketing",
      name: "marketing",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "voucher",
          name: "mkt_voucher",
          component: () => import("@/view/pages/marketing/Voucher.vue"),
        },
      ]
    },
  ],
});
